import React, { useState } from 'react';
import logo from './apparellogo2.png'; 
import patreonlogo from './PATREON_SYMBOL_1_WHITE_RGB.png';
import UpcomingEpisodes from './UpcomingEpisodes'; // Add this import
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 


const games = [
  { id: 1, name: 'Baldurs Gate 3', link: 'https://www.youtube.com/watch?v=jLz5kKDaIJM'  },
  { id: 2, name: 'Disco Elysium', link: 'https://www.youtube.com/watch?v=guH8DRgJ6cg' },
  { id: 12, name: 'Doom (1993)', link:'https://www.youtube.com/watch?v=jkmw2-uyWHg' },
  { id: 11, name: 'Prey (2017)', link:'https://www.youtube.com/watch?v=8OHdnXHugrQ' },
  { id: 3, name: 'Zelda: TOTK', link: 'https://www.youtube.com/watch?v=08O8WMc9jWQ' },
  { id: 5, name: 'Death Stranding (DC)', link: 'https://www.youtube.com/watch?v=CM6m9AIXCeI' },
  { id: 6, name: 'Hollow Knight', link: 'https://www.youtube.com/watch?v=Ootx2OvyRxU' },
  { id: 7, name: 'The Last Us Pt II', link: 'https://www.youtube.com/watch?v=iFJCcZ8dQwg' },
  { id: 8, name: 'The Last Of Us', link: 'https://www.youtube.com/watch?v=MqYe2Gom3X4' },
  { id: 79, name: '1000xRESIST', link: 'https://www.youtube.com/watch?v=kuwm03ugmq4' },
  { id: 4, name: 'Alan Wake II', link: 'https://www.youtube.com/watch?v=Xi1BpVEacsQ'  },
  { id: 66, name: 'Pokémon Gold/Silver', link:'https://www.youtube.com/watch?v=eruoYBt7Ue0' }, 
  { id: 75, name: 'Silent Hill 2 Remake', link:'https://www.youtube.com/watch?v=ukqzH2uyXB8' }, 
  { id: 10, name: 'Grand Theft Auto V', link: 'https://www.youtube.com/watch?v=ggvL5iMHbNY' },
  { id: 9, name: 'Portal 2', link: 'https://www.youtube.com/watch?v=f7r-VXREpD0' },
  { id: 68, name: 'Titanfall 2', link: 'https://www.youtube.com/watch?v=WpOaRxJv7Xc' },
  { id: 13, name: 'Humanity', link:'https://www.youtube.com/watch?v=xlisS9kln6k' }, 
  { id: 14, name: 'Slay The Spire', link:'https://www.youtube.com/watch?v=VKcFyyZotjs' }, 
  { id: 15, name: 'The Walking Dead S1', link:'https://www.youtube.com/watch?v=acSY72CSpdw' }, 
  { id: 16, name: 'Half Life: Alyx', link:'https://www.youtube.com/watch?v=p7D55QdcDlo' }, 
  { id: 71, name: 'Donkey Kong 94', link:'https://www.youtube.com/watch?v=DAox8yn0_I4' }, 
  { id: 17, name: 'Undertale', link:'https://www.youtube.com/watch?v=VgmWA9IsviU' }, 
  { id: 18, name: 'LaD: Infinite Wealth', link:'https://www.youtube.com/watch?v=ZmB1Bfci6eY' }, 
  { id: 72, name: 'Helldivers 2', link:'https://www.youtube.com/watch?v=q2Wtx2hQUNg'},
  { id: 19, name: 'Earthbound', link:'https://www.youtube.com/watch?v=D7PeVsa0fSc' }, 
  { id: 20, name: 'Pikmin 4', link:'https://www.youtube.com/watch?v=gj8IblchLt0' },
  { id: 21, name: 'Yoshis Island', link:'https://www.youtube.com/watch?v=qSawOZjasx0' }, 
  { id: 22, name: 'Sub Terra (Board game)', link:'https://www.youtube.com/watch?v=hud-l379C-0' }, 
  { id: 23, name: 'Outer Wilds', link:'https://www.youtube.com/watch?v=r5rS4cUUd1M' }, 
  { id: 24, name: 'Killer 7', link:'https://www.youtube.com/watch?v=fTEC4SPP6jY' },
  { id: 35, name: 'Driver San Francisco', link:'https://www.youtube.com/watch?v=b8dAx5_cju8' },
  { id: 82, name: 'DQ Rocket Slime', link:'https://www.youtube.com/watch?v=-uljjCbWDRc' },
  { id: 69, name: 'Indika', link:'https://www.youtube.com/watch?v=VO2K_69JrpY' }, 
  { id: 26, name: 'WRo Edith Finch', link:'https://www.youtube.com/watch?v=2EyieuurYx4' }, 
  { id: 69, name: 'Dead Rising', link:'https://www.youtube.com/watch?v=b-1O-oke-so' }, 
  { id: 67, name: 'Skate (2007)', link:'https://www.youtube.com/watch?v=ZIxk37qYCqA' }, 
  { id: 27, name: 'Alan Wake', link:'https://www.youtube.com/watch?v=lzjOgtT0Kak' }, 
  { id: 25, name: 'Harold Halibut', link:'https://www.youtube.com/watch?v=i9uSjVFM-hM' },
  { id: 28, name: 'Jet Set Radio Future', link:'https://www.youtube.com/watch?v=R7X5IUApfXE' }, 
  { id: 29, name: 'Goodboy Galaxy', link:'https://www.youtube.com/watch?v=bZpq3JOU4pg' }, 
  { id: 30, name: 'Ooblets', link:'https://www.youtube.com/watch?v=Y8ZouNgobpw' }, 
  { id: 31, name: 'Mr Driller: Drill Land', link:'https://www.youtube.com/watch?v=0zrueLTUz3g' }, 
  { id: 33, name: 'The Forgotten City', link:'https://www.youtube.com/watch?v=5MiqlWrpP8s' }, 
  { id: 41, name: 'ZeroRanger', link:'https://www.youtube.com/watch?v=Bis0jzIejoM' }, 
  { id: 33, name: 'Superbrothers S&S EP', link:'https://www.youtube.com/watch?v=_MF-S8N40LQ' }, 
  { id: 34, name: 'Resident Evil (2002)', link:'https://www.youtube.com/watch?v=6IlZMEqNDoc' }, 
  { id: 70, name: 'Splinter Cell: Blacklist', link:'https://www.youtube.com/watch?v=Q4KJFaCzfJ4' }, 
  { id: 36, name: 'Phoenix Wright: AA', link:'https://www.youtube.com/watch?v=571cSKLjqDk' }, 
  { id: 37, name: 'Halo: Infinite', link:'https://www.youtube.com/watch?v=0ddGHjml6BI' },
  { id: 37, name: 'F.E.A.R', link:'https://www.youtube.com/watch?v=c5m5YGgFY8Y' },
  { id: 37, name: 'Metal Gear Rising', link:'https://www.youtube.com/watch?v=hJmh-x8Zzus' },
  { id: 32, name: 'League of Legends', link:'https://www.youtube.com/watch?v=kS_DgNwKmRM' }, 
  { id: 38, name: 'Ridge Racer Type 4', link:'https://www.youtube.com/watch?v=oO_XYpmZD7o' },
  { id: 39, name: 'Super Metroid', link:'https://www.youtube.com/watch?v=_1C2FvDZqZ4' }, 
  { id: 40, name: 'Warcraft II', link:'https://www.youtube.com/watch?v=OOS67K-ryPU' }, 
  { id: 54, name: 'The Beginners Guide', link:'https://www.youtube.com/watch?v=p13u9xmAWDo' }, 
  { id: 54, name: 'Papers Please', link:'https://www.youtube.com/watch?v=zfvvjsc9GIA' }, 
  { id: 42, name: 'Survival Kids', link:'https://www.youtube.com/watch?v=Ipac7P2myzs' }, 
  { id: 43, name: 'XCOM 2: WOTC', link:'https://www.youtube.com/watch?v=GGI8VyuUJAs' }, 
  { id: 44, name: 'Skyrim', link:'https://www.youtube.com/watch?v=uys2RrfwXlE' }, 
  { id: 45, name: 'Alien: Isolation', link:'https://www.youtube.com/watch?v=zzKd1U5_qwQ' }, 
  { id: 46, name: 'Kero Blaster', link:'https://www.youtube.com/watch?v=E1K1Q_hBdoE' }, 
  { id: 47, name: 'The Frog Detective', link:'https://www.youtube.com/watch?v=q0hbGiLrCVM' }, 
  { id: 48, name: 'Ratchet and Clank (2016)', link:'https://www.youtube.com/watch?v=4A6t30q3J38' }, 
  { id: 49, name: 'Bioshock 2', link:'https://www.youtube.com/watch?v=OrPDOasx4LU' }, 
  { id: 51, name: 'Elite (1984)', link:'https://www.youtube.com/watch?v=9BQrmj77gGI' }, 
  { id: 52, name: 'The Dig', link:'https://www.youtube.com/watch?v=qLAHS_rlikY' }, 
  { id: 53, name: 'Sonic 3 & Knuckles', link:'https://www.youtube.com/watch?v=WZ8TXH0UXcg' }, 
  { id: 50, name: 'Dredge', link:'https://www.youtube.com/watch?v=FJ3wg7LQPvE' }, 
  { id: 55, name: 'Ominusha: Warlords', link:'https://www.youtube.com/watch?v=S-JdjO2deIo' }, 
  { id: 56, name: 'Max Payne 3', link:'https://www.youtube.com/watch?v=wVEliVYQk8k' }, 
  { id: 57, name: 'Kuru Kuru Kururin', link:'https://www.youtube.com/watch?v=HKAGDZFm0hk' }, 
  { id: 55, name: 'World Of Goo', link:'https://www.youtube.com/watch?v=oqBZqoxhv7c' }, 
  { id: 73, name: 'The Plucky Squire', link:'https://www.youtube.com/watch?v=ZUrA1j0pHGo' }, 
  { id: 65, name: 'ZoE 2: 2nd Runner', link:'https://www.youtube.com/watch?v=uOjvTO81rw8' },
  { id: 58, name: 'World of Warcraft', link:'https://www.youtube.com/watch?v=swVjB1TGKDY', tooltip:'For the Alliance! This should be top 5!' }, 
  { id: 59, name: 'Rogue (Epyx)', link:'https://www.youtube.com/watch?v=n8tUk1vG2fA' }, 
  { id: 60, name: 'Enslaved (OttW)', link:'https://www.youtube.com/watch?v=9hjeqh7X-TM' }, 
  { id: 61, name: 'Harry Potter (atCoS)', link:'https://www.youtube.com/watch?v=FSkbtnocWRU' }, 
  { id: 62, name: 'Ty the Tasmanian Tiger', link:'https://www.youtube.com/watch?v=arJP2Zd7uJA' }, 
  { id: 63, name: 'Beyond Good & Evil', link:'https://www.youtube.com/watch?v=SMpkUoHDk-Q' }, 
    { id: 81, name: 'Alpha Protocol', link:'https://www.youtube.com/watch?v=vt8qrA9RGpA' }, 
  { id: 64, name: 'L.A. Noire', link:'https://www.youtube.com/watch?v=aXp_y5JV_6o' }, 
  

];

// Sort games alphabetically by name
//games.sort((a, b) => a.name.localeCompare(b.name));


const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const episodes = [
  { name: 'Dead Island 2', date: '18th April' },
  { name: 'Batman: Arkham Asylum', date: '16th May' },
  { name: 'Doom: The Dark Ages', date: '13th June' },
  { name: 'TBC', date: '11th July' },
  { name: 'Death Stranding 2: On The Beach', date: '8th August' },
];



    // Dynamically calculate the height for the timeline based on the number of episodes
    const getTimelineHeight = () => {
      if (episodes.length === 1) {
        return '1%'
      }else if (episodes.length === 6) {
        return '83%'; 
      } else if (episodes.length === 3) {
        return '69%';
      } else if (episodes.length === 2) {
        return '50%';
      } else if (episodes.length === 4) {
        return '75%';
      } else if (episodes.length === 5) {
        return '80%';
      } else if (episodes.length === 6) {
        return '83%';
      } else {
        return '100%'; // Default height
      }
    };

const GameList = () => {
  const gameChunks = chunkArray(games, 5);

  return (
    <div className="content">
      <header>
      <a href="https://www.youtube.com/channel/UC5QE2hDvhZz3TfLvzTVZySQ" target="_blank" className="header-image-link">
        <img src={logo} alt="Header" className="header-image" />
      </a>

        
      </header>

      <div class="links">
        <a href="https://Patreon.com/thebonuspoints" target="_blank">
            <img class='btn' src="disc-and-patreon.png" alt="Discord & Patreon"  />
        </a>
        <a href="https://bsky.app/profile/thebonuspoints.com" target="_blank">
            <img class='btn' src="bluesky.png" alt="BlueSky Profile"  />
        </a>
        <a href="https://open.spotify.com/show/1sVlgLS2boSqY0coeS7AUt?si=af28b2b2087349f9" target="_blank">
            <img class='btn' src="spotify.png" alt="Spotify"  />
        </a>

        <a href="https://the-bonus-points-shop.fourthwall.com/" target="_blank">
            <img class='btn' src="merch.png" alt="Merchandise"  />
        </a>

        <a href="https://www.instagram.com/thebonuspoints/" target="_blank">
            <img class='btn' src="instagram.png" alt="Instagram"  />
        </a>
        <a href="https://www.youtube.com/channel/UC5QE2hDvhZz3TfLvzTVZySQ" target="_blank">
            <img class='btnr' src="youtube.png" alt="Youtube"  />
        </a>

        </div>
      <div class="thelist">
        <div class="header-container">
          <div class="thelistHeader">- THE LIST -</div>
          <div class="additionalText">Updated the day before the newest episode! - Click a game to watch the episode
</div>
        </div>
           <div className="grid-container">
            {gameChunks.map((chunk, index) => (
              <div key={index} className="column">
                <div className="game-list">
                  {chunk.map((game, gameIndex) => (
                    <li key={gameIndex} className="game-item">
                      <a href={game.link} className="game-link" target="_blank" title={game.tooltip}><span className="game-number">{index * 5 + gameIndex + 1}.</span> {game.name}
                      </a>
                    </li>
                  ))}
                </div>
              </div>
            ))}
        </div>
 
      </div>
      <h1 className='upcoming-header'>Upcoming Podcast Episodes</h1>
      <div class='timeline'>
      <VerticalTimeline lineColor='#6CBAEA' layout='2-columns'>
        
        {episodes.map((episode, index) => (
          <VerticalTimelineElement 
          contentArrowStyle={{borderRight: '7px solid #6CBAEA'}}
            key={index}
            iconStyle={{ background: '#f7e017', color: '#6CBAEA',borderColor: '#6CBAEA' }}
             
          >
            <h3 className="vertical-timeline-element-title">{episode.name}</h3>
            <h4 className="vertical-timeline-element-subtitle">{episode.date}</h4>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
      </div>
      <footer className="footer">
        <p>Created by <a href="https://github.com/neilwatts86/" target="_blank" rel="noopener noreferrer" className="footer-link">Neil Watts</a>, on behalf of The Bonus Points</p>
      </footer>

      <style>
        {`
          .vertical-timeline::before {
            height: ${getTimelineHeight()} !important;
          }
        `}
      </style>

    </div>
  );
};

export default GameList;
